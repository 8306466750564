<template>
  <div id="user-table-list">
    <div class="top-head">
      <div class="left">
        <router-link
          :to="{ name: 'GroupTableListAll', params: { ...$route.params } }"
          v-if="checkHasPermission('view-groups') && canGoBack"
          class="btn"
        >
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t('app.back') }}</span>
          </span>
        </router-link>
        <router-link
          :to="getDynamicPreviousRoute"
          class="btn"
          v-else-if="canGoBack"
        >
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t('app.back') }}</span>
          </span>
        </router-link>
        <span>{{ $tc('app.user.title', 2) }}</span>
        <span
          :title="$i18n.tc('app.client.title', 1)"
          v-if="isMultipleClients && clientData.name"
          >({{ clientData.name }})</span
        >
        <span
          :title="$i18n.tc('app.group.title', 1)"
          v-if="
            checkHasPermission('view-groups') &&
            canGoBack &&
            $route.params.groupData
          "
          >- ({{ $route.params.groupData.name }})</span
        >
      </div>
      <div class="center">
        <table-filters
          :values="routeQuery"
          :display="tableFilterDisplay"
          @search-input="routeQuery.search = $event.target.value"
          @role-select="roleSelected"
          @active-status-select="activeStatusSelected"
        />
      </div>
      <div class="right">
        <router-link
          :to="{
            name: 'UserAdd',
            params: { user_id: 'add', group_id: $route.params.group_id },
          }"
          v-if="checkHasPermission('create-users')"
        >
          <button class="acc">
            <span>
              <i class="fa fa-plus"></i>
              <span>{{ $t('app.user.addNew') }}</span>
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <transition name="slide-left">
      <div class="table-wrap" v-if="tableShowFunc">
        <table-wrapper
          class="table"
          :rows="usersData"
          :columns="columnsToShow"
          :options="tableOptions"
          @row-select="rowSelect"
          @toggle-activation="toggleActiveState"
        >
          <router-link :to="{ name: '' }">
            <button class="mini" @click.stop>Edit</button>
          </router-link>
        </table-wrapper>
        <pagination-wrap
          :route-query="routeQuery"
          :pagination="pagination"
        ></pagination-wrap>
      </div>
    </transition>
  </div>
</template>

<script>
import TableFilters from '@/components/Shared/table-filters/TableFilters.vue';
import TableWrapper from '@/components/Shared/table-wrap/TableWrapper.vue';
import { mapActions } from 'vuex';
export default {
  name: 'UserTableList',
  components: {
    TableFilters,
    TableWrapper,
  },
  props: {
    // usersData: {
    //   type: Array,
    //   default: () => ([])
    // },
  },
  data () {
    return {
      usersData: [],
      tableShowFunc: false,
      roleSelection: null,
      activeStatusSelection: true,
      pagination: {
        totalPages: 0,
      },
      routeQuery: {
        page: 1,
        search: '',
      },
      keysTransform: ['page'],
      transformMap: {
        usr_id: 'id',
        usr_first_name: 'name',
        usr_last_name: 'lastname',
        usr_description: 'description',
        usr_email: 'email',
        usr_phone: 'phone',
        usr_active: '_isActiveToggle',
      },
    };
  },
  computed: {
    canGoBack () {
      return this.$route.name !== 'UserTableListAll';
    },
    columnsToShow () {
      return [
        {
          name: 'id',
          hidden: true,
        },
        // {
        //   name: 'name',
        //   label: this.$i18n.t('auth.name'),
        //   style: {
        //     width: '200px'
        //   }
        // },
        {
          name: 'fullName',
          label: this.$i18n.t('auth.name'),
          style: {
            width: '250px',
          },
        },
        // {
        //   name: 'lastname',
        //   label: this.$i18n.t('auth.lastName'),
        //   style: {
        //     width: '150px'
        //   }
        // },
        // {
        //   name: 'description',
        //   label: this.$i18n.t('app.description'),
        //   style: {
        //     width: '200px'
        //   }
        // },
        // {
        //   name: 'rls_name',
        //   label: this.$i18n.tc('auth.role', 1),
        //   hiddenMobile: true,
        //   style: {
        //     width: '150px'
        //   }
        // },
        {
          name: 'phone',
          label: this.$i18n.t('auth.phone'),
        },
        {
          name: 'max_mmb_date_to',
          type: 'date',
          format: '',
          label: this.$i18n.t('app.lastMembershipDate'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'max_cls_date',
          type: 'date',
          format: '',
          label: this.$i18n.t('app.lastClassData'),
          style: {
            width: '200px',
          },
          hidden: this.usersData.every(user => !user.max_cls_date),
        },
        // {
        //   name: 'email',
        //   label: this.$i18n.t('auth.email'),
        //   style: {
        //     width: '200px'
        //   }
        // },
        {
          name: 'activate',
          label: this.$i18n.t('selection.activeStatus'),
          permissions: 'activate-deactivate-users',
          isMobileHeader: true,
          slots: {
            activate: true,
          },
        },
        {
          name: 'memberships',
          label: '',
          permissions: 'view-memberships',
          style: {
            width: '150px',
          },
          slots: {
            memberships: true,
          },
        }
      ];
    },
    tableOptions () {
      return {
        rowClasses: {
          selectable: this.checkHasPermission('edit-users'),
        },
      };
    },
    tableFilterDisplay () {
      return {
        roles: true,
        activeStatus: this.checkHasPermission('activate-deactivate-users'),
      };
    },
  },
  watch: {
    $route (newValue, oldValue) {
      this.routeQuery = { ...this.queryStringToInt(this.$route.query, this.keysTransform) };
      this.fetchUsersList();
    },
  },
  mounted () {
    this.routeQuery = { ...this.routeQuery, ...this.queryStringToInt(this.$route.query, this.keysTransform) };
    this.$nextTick(() => (this.tableShowFunc = true));
    this.fetchUsersList();
  },
  beforeDestroy () {
    this.$nextTick(() => (this.tableShowFunc = false));
  },
  methods: {
    ...mapActions({
      getUsers: 'getUsers',
      patchUser: 'patchUser',
    }),
    async fetchUsersList () {
      const params = {
        search: this.$route.query.search || null,
        role_ids: this.roleSelection || null, // null because of all roles
        usr_active: this.activeStatusSelection,
        grp_id: this.$route.params.group_id,
        page: this.$route.query.page || null,
        perPage: this.$route.query.perPage || 5,
      };
      try {
        const res = await this.getUsers(params);
        let tempData = res.data.data || [];
        tempData = tempData.map(responseObj => this.transformApiDataObject(responseObj, this.transformMap, { isKeepClone: false }));
        this.usersData = this.addAdditionalData(tempData);
        this.pagination.totalPages = res.data.meta?.pageCount || 0;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    addAdditionalData (tempData) {
      function fullUserName (data) {
        const firstName = data.name || '';
        const lastName = data.lastname || '';
        return `${firstName} ${lastName}`;
      }

      for (const data of tempData) {
        data.fullName = fullUserName(data);
      }

      return tempData;
    },
    async rowSelect (rowEvt) {
      const params = {
        user_id: rowEvt.id,
      };
      this.$router.push({
        name: 'UserEdit',
        params: {
          user_id: rowEvt.id,
          initialData: rowEvt,
        },
      });
    },
    roleSelected (evt) {
      this.roleSelection = evt;
      this.resetPageFilter();
      this.fetchUsersList();
    },
    activeStatusSelected (evt) {
      this.activeStatusSelection = evt;
      this.resetPageFilter();
      this.fetchUsersList();
    },
    async toggleActiveState (event) {
      // console.log('toggleActiveState', evt);
      const params = {
        id: event.row.id,
        usr_active: event.evt.checked,
      };
      try {
        const res = await this.patchUser(params);
        this.showSuccessMsg(res.data.message);
        this.fetchUsersList();
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#user-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;
}
</style>
