var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-table-list"},[(_vm.columnsFiltered.length)?[_c('div',{staticClass:"header"},_vm._l((_vm.columnsFiltered),function(column){return _c('span',{key:column.id,staticClass:"cell",style:([_vm.columnCellStyle(column)])},[_c('span',[_vm._v(_vm._s(column.label))])])}),0),_c('div',{staticClass:"body"},_vm._l((_vm.rows),function(row,rowIndex){return _c('div',{key:row.id,staticClass:"row",class:_vm.dynRowClass(row),on:{"click":function($event){return _vm.rowCardSelected($event, row)}}},_vm._l((_vm.columnsFiltered),function(column){return _c('div',{key:column.id,staticClass:"cell",class:{ slot: column.slots && Object.keys(column.slots).length },style:([_vm.columnCellStyle(column)])},[_c('table-cell',{attrs:{"cell-data":row[column.name],"row":row,"column":column}},[(
                column.slots &&
                column.slots.remove &&
                column.canShowCellFunc &&
                column.canShowCellFunc(row)
              )?[_c('v-popover',{ref:'popover-remove-' + rowIndex,refInFor:true},[_c('button',{staticClass:"mini close-btn",on:{"click":function($event){$event.stopPropagation();return _vm.showPopoverRemove(rowIndex)}}},[_c('i',{staticClass:"fa fa-times"})]),_c('template',{slot:"popover"},[_c('div',{staticClass:"popover-action-slot"},[_c('span',[_vm._v(_vm._s(_vm.$t('app.confirmProceed'))+"?")]),_c('div',{staticClass:"action"},[_c('button',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"acc",on:{"click":function($event){$event.stopPropagation();return _vm.remove($event, row, rowIndex)}}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('app.confirm')))])])]),_c('button',{directives:[{name:"close-popover",rawName:"v-close-popover"}],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('app.cancel')))])])])])])])],2)]:_vm._e(),(column.slots && column.slots.classes)?[_c('router-link',{staticClass:"classes-btn",attrs:{"to":{
                  name: 'ClassTableListAll',
                  params: {
                    client_id: _vm.clientData.id,
                    group_id: row.id,
                    groupData: row,
                  },
                },"title":_vm.$i18n.tc('app.class.title', 2)}},[_c('i',{staticClass:"fa fa-graduation-cap"}),_c('span',[_vm._v(_vm._s(_vm.$tc('app.class.title', 2)))])])]:_vm._e(),(column.slots && column.slots.memberships)?[_c('router-link',{staticClass:"classes-btn",attrs:{"to":{
                  name: 'MembershipTableUser',
                  params: { client_id: _vm.clientData.id, user_id: row.id },
                },"title":_vm.$tc('app.membership.title', 2)}},[_c('button',{staticClass:"mini acc alt"},[_c('span',[_c('i',{staticClass:"fa fa-id-card"}),_c('span',[_vm._v(_vm._s(_vm.$tc('app.membership.title', 2)))])])])])]:_vm._e(),(column.slots && column.slots.editGroup)?[_c('router-link',{staticClass:"group-btn",attrs:{"to":{
                  name: 'GroupEdit',
                  params: { client_id: _vm.clientData.id, group_id: row.id },
                }}},[_c('i',{staticClass:"fa fa-pen"}),_c('span',[_vm._v(_vm._s(_vm.$t('app.editGroup')))])])]:_vm._e(),(column.slots && column.slots.editClient)?[_c('router-link',{staticClass:"group-btn",attrs:{"to":{ name: 'ClientEdit', params: { client_id: row.id } }}},[_c('i',{staticClass:"fa fa-pen"}),_c('span',[_vm._v(_vm._s(_vm.$t('app.editClient')))])])]:_vm._e(),(column.slots && column.slots.activate)?[_c('v-popover',{ref:'popover-status-' + rowIndex,refInFor:true},[_c('checkbox-input',{staticClass:"tooltip-target b3",attrs:{"checked":row._isActiveToggle === 1,"options":_vm.mergedOptions.activationOptions},on:{"change":function($event){return _vm.showPopoverActive(rowIndex)}},nativeOn:{"click":function($event){$event.stopPropagation();}}}),_c('template',{slot:"popover"},[_c('div',{staticClass:"popover-action-slot"},[_c('span',[_vm._v(_vm._s(_vm.$t('app.confirmProceed'))+"?")]),_c('div',{staticClass:"action"},[_c('button',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"acc",on:{"click":function($event){$event.stopPropagation();return _vm.toggleActivation($event, row, rowIndex)}}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('app.confirm')))])])]),_c('button',{directives:[{name:"close-popover",rawName:"v-close-popover"}],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t('app.cancel')))])])])])])])],2)]:_vm._e()],2)],1)}),0)}),0)]:_vm._e(),(_vm.columnsFiltered.length === 0)?_c('div',{staticClass:"no-data"},[_c('span',[_vm._v(_vm._s(_vm.$t('app.table.noData')))])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }