<template>
  <div class="custom-table-list">
    <template v-if="columnsFiltered.length">
      <!-- <div class="header">
        <span
          class="cell"
          :style="[columnCellStyle(column, 'mob')]"
          v-for="column in columnsFiltered"
          :key="column.id"
        >
          <span>{{column.label}}</span>
        </span>
      </div>-->
      <div class="body">
        <div
          class="row"
          :class="dynRowClass(row)"
          v-for="(row,rowIndex) in rows"
          :key="row.id"
          @click="rowCardSelected($event, row)"
        >
          <span
            class="cell"
            :class="{slot: column.slots && Object.keys(column.slots).length}"
            :style="[columnCellStyle(column, 'mob')]"
            v-for="column in columnsFiltered"
            :key="column.id"
          >
            <div
              class="cell-wrapper"
              :class="{'end': column.slots && column.slots.remove}"
            >
              <table-mob-header
                :cell-data="row[column.name]"
                :row="row"
                :column="column"
                v-if="column.isMobileHeader"
              ></table-mob-header>
              <table-cell
                :cell-data="row[column.name]"
                :row="row"
                :column="column"
              >
                <!-- Remove btn -->
                <template v-if="column.slots && column.slots.remove && column.canShowCellFunc && column.canShowCellFunc(row)">
                  <v-popover :ref="'popover-remove-'+rowIndex">
                    <button
                      class="mini close-btn"
                      @click.stop="showPopoverRemove(rowIndex)"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                    <template slot="popover">
                      <div class="popover-action-slot">
                        <span>{{$t('app.confirmProceed')}}?</span>
                        <div class="action">
                          <button
                            class="acc"
                            @click.stop="remove($event, row, rowIndex)"
                            v-close-popover
                          >
                            <span>
                              <span>{{$t('app.confirm')}}</span>
                            </span>
                          </button>
                          <button
                            @click.stop.prevent
                            v-close-popover
                          >
                            <span>
                              <span>{{$t('app.cancel')}}</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </template>
                  </v-popover>
                </template>
                <!-- Classes btn -->
                <template v-if="column.slots && column.slots.classes">
                  <router-link
                    :to="{ name: 'ClassTableListAll', params: {client_id: clientData.id, group_id: row.id}}"
                    class="classes-btn"
                    title="Classes"
                  >
                    <i class="fa fa-graduation-cap"></i>
                    <span>{{$tc('app.class.title',2)}}</span>
                  </router-link>
                </template>
                <!-- Membership btn -->
                <template v-if="column.slots && column.slots.memberships">
                  <router-link
                    :to="{ name: 'MembershipTableUser', params: {client_id: clientData.id, user_id: row.id}}"
                    class="classes-btn"
                    title="Classes"
                  >
                    <button class="mini acc alt">
                      <span>
                        <i class="fa fa-id-card"></i>
                        <span>{{$tc('app.membership.title',2)}}</span>
                      </span>
                    </button>
                  </router-link>
                </template>
                <!-- Edit Group -->
                <template v-if="column.slots && column.slots.editGroup">
                  <router-link
                    :to="{ name: 'GroupEdit', params: {client_id: clientData.id, group_id: row.id}}"
                    class="group-btn"
                  >
                    <i class="fa fa-pen"></i>
                    <span>{{$t('app.editGroup')}}</span>
                  </router-link>
                </template>
                <!-- Edit Group -->
                <template v-if="column.slots && column.slots.editClient">
                  <router-link
                    :to="{ name: 'ClientEdit', params: {client_id: row.id}}"
                    class="group-btn"
                  >
                    <i class="fa fa-pen"></i>
                    <span>{{$t('app.editClient')}}</span>
                  </router-link>
                </template>
                <!-- Active Toggle Switch -->
                <template v-if="column.slots && column.slots.activate">
                  <v-popover :ref="'popover-status-'+rowIndex">
                    <checkbox-input
                      class="tooltip-target b3"
                      :checked="row._isActiveToggle === 1"
                      :options="mergedOptions.activationOptions"
                      @change="showPopoverActive(rowIndex)"
                      @click.native.stop
                    ></checkbox-input>
                    <template slot="popover">
                      <div class="popover-action-slot">
                        <span>{{$t('app.confirmProceed')}}?</span>
                        <div class="action">
                          <button
                            class="acc"
                            @click.stop="toggleActivation($event, row, rowIndex)"
                            v-close-popover
                          >
                            <span>
                              <span>{{$t('app.confirm')}}</span>
                            </span>
                          </button>
                          <button
                            @click.stop.prevent
                            v-close-popover
                          >
                            <span>
                              <span>{{$t('app.cancel')}}</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </template>
                  </v-popover>
                </template>
              </table-cell>
            </div>
          </span>
        </div>
      </div>
    </template>
    <div
      class="no-data"
      v-if="columnsFiltered.length === 0"
    >
      <span>{{$t('app.table.noData')}}</span>
    </div>
  </div>
</template>

<script>
import TableMobHeader from '@/components/Shared/table/TableMobHeader.vue';
import TableCell from '@/components/Shared/table/TableCell.vue';
import tableMixin from './tableMixin.js';
export default {
  components: {
    TableMobHeader,
    TableCell,
  },
  mixins: [tableMixin],
};
</script>

<style lang="scss" scoped>
.custom-table-list {
  @include table-elements;
  $card-shadow: 0px 1px 7px 1px $global-shadow-color;
  display: flex;
  flex-direction: column;
  color: #a19fa8;
  font-weight: 500;
  font-size: 0.85rem;

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: $table-text;
    padding: 5px;
    // padding: 10px 20px;
    // overflow: hidden;

    .row {
      display: grid;
      gap: 5px;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-template-areas:
        'text slot'
        'text slot';
      grid-auto-rows: minmax(40px, auto);
      padding: 10px 12px;
      background: white;
      box-shadow: $card-shadow;
      border-radius: 5px;
      transition: all 0.2s ease;
      overflow: auto;
      // border-left: 4px solid;
      // border-left-color: silver;

      @media screen and (min-width: 768px) {
        grid-auto-rows: 40px;
      }

      &.selectable {
        user-select: none;
        cursor: pointer;
      }

      &.disabled-low {
        background: #00000012;
        opacity: 0.7;
        cursor: not-allowed;
      }

      &:hover {
        border-left-color: $accent-color;
        // transform: translateX(5px);
        box-shadow: 0px 2px 7px 0px darken($global-shadow-color, 50);
      }

      > .cell {
        display: flex;

        .cell-wrapper {
          display: flex;
          flex: 1;
          align-items: center;
          gap: 10px;

          &.end {
            justify-content: flex-end;
          }
        }

        // WIP
        // &:not(.slot) {
        //   grid-area: text;
        // }

        // &.slot {
        //   grid-area: slot;
        // }

        .table-cell {
          padding: 3px 0;
        }
      }
    }
  }
}
</style>
