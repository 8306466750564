var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-table-list"}},[_c('div',{staticClass:"top-head"},[_c('div',{staticClass:"left"},[(_vm.checkHasPermission('view-groups') && _vm.canGoBack)?_c('router-link',{staticClass:"btn",attrs:{"to":{ name: 'GroupTableListAll', params: Object.assign({}, _vm.$route.params) }}},[_c('span',[_c('i',{staticClass:"fa fa-chevron-left"}),_c('span',[_vm._v(_vm._s(_vm.$t('app.back')))])])]):(_vm.canGoBack)?_c('router-link',{staticClass:"btn",attrs:{"to":_vm.getDynamicPreviousRoute}},[_c('span',[_c('i',{staticClass:"fa fa-chevron-left"}),_c('span',[_vm._v(_vm._s(_vm.$t('app.back')))])])]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$tc('app.user.title', 2)))]),(_vm.isMultipleClients && _vm.clientData.name)?_c('span',{attrs:{"title":_vm.$i18n.tc('app.client.title', 1)}},[_vm._v("("+_vm._s(_vm.clientData.name)+")")]):_vm._e(),(
          _vm.checkHasPermission('view-groups') &&
          _vm.canGoBack &&
          _vm.$route.params.groupData
        )?_c('span',{attrs:{"title":_vm.$i18n.tc('app.group.title', 1)}},[_vm._v("- ("+_vm._s(_vm.$route.params.groupData.name)+")")]):_vm._e()],1),_c('div',{staticClass:"center"},[_c('table-filters',{attrs:{"values":_vm.routeQuery,"display":_vm.tableFilterDisplay},on:{"search-input":function($event){_vm.routeQuery.search = $event.target.value},"role-select":_vm.roleSelected,"active-status-select":_vm.activeStatusSelected}})],1),_c('div',{staticClass:"right"},[(_vm.checkHasPermission('create-users'))?_c('router-link',{attrs:{"to":{
          name: 'UserAdd',
          params: { user_id: 'add', group_id: _vm.$route.params.group_id },
        }}},[_c('button',{staticClass:"acc"},[_c('span',[_c('i',{staticClass:"fa fa-plus"}),_c('span',[_vm._v(_vm._s(_vm.$t('app.user.addNew')))])])])]):_vm._e()],1)]),_c('transition',{attrs:{"name":"slide-left"}},[(_vm.tableShowFunc)?_c('div',{staticClass:"table-wrap"},[_c('table-wrapper',{staticClass:"table",attrs:{"rows":_vm.usersData,"columns":_vm.columnsToShow,"options":_vm.tableOptions},on:{"row-select":_vm.rowSelect,"toggle-activation":_vm.toggleActiveState}},[_c('router-link',{attrs:{"to":{ name: '' }}},[_c('button',{staticClass:"mini",on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("Edit")])])],1),_c('pagination-wrap',{attrs:{"route-query":_vm.routeQuery,"pagination":_vm.pagination}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }