<template>
  <div id="group-add">
    <div class="form">
      <div
        class="close-btn"
        @click="routeGoBackCheck()"
      >
        <div class="fa fa-times"></div>
      </div>
      <div class="header">
        <span>{{$tc('app.group.title',1)}}</span>
      </div>
      <div class="input-group">
        <input
          class="alt"
          v-model.trim="form.grp_name"
          :placeholder="placeholderTranslations.name"
          type="text"
          required
        />
      </div>
      <div class="input-group">
        <textarea
          class="alt"
          v-model="form.grp_description"
          :placeholder="placeholderTranslations.description"
          :title="placeholderTranslations.description"
          type="text"
        />
      </div>
      <div class="input-group">
        <multiselect-view
          :handleProps="locationsProps"
          :handleEvents="locationsEvents"
          :slot-text="multiSlotsMultiselect"
          required
        />
      </div>
      <div class="input-group" v-if="groupId">
        <multiselect-view
          :handleProps="usersProps"
          :handleEvents="usersEvents"
          :slot-text="multiSlotsMultiselect"
        />
      </div>
      <div class="text-mini">* {{$t('app.requiredFields')}}</div>
      <button class="acc" :disabled="isApiLoading" @click="manageGroup">
        <span :class="{'progress': isApiLoading}">
          <i class="fa fa-save"></i>
          <span>{{$t('app.save')}}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  mixins: [formMixin],
  data () {
    return {
      groupId: null,
      formBaseData: {},
      form: {},
      isApiLoading: false,
      locations: [],
      usersData: [],
      transformMap: {
        grp_id: 'id',
      },
      includeKeys: [
        'id',
        'grp_id',
        'grp_name',
        'lct_id',
        'user_ids',
        // 'grp_active', // Used in table list
        'grp_description'
      ],
    };
  },
  computed: {
    locationsProps () {
      return {
        // __isFixedDrop: true,
        options: this.locations,
        placeholder: this.placeholderTranslations.location,
        value: this.locations.find(item => item.lct_id === this.form.lct_id),
        searchable: true,
        label: 'lct_name',
      };
    },
    locationsEvents () {
      return {
        select: (evt) => this.$set(this.form, 'lct_id', evt.lct_id),
        remove: () => this.$set(this.form, 'lct_id', null),
      };
    },
    usersProps () {
      return {
        // __isFixedDrop: true,
        options: this.usersData,
        placeholder: this.placeholderTranslations.user,
        title: this.placeholderTranslations.user,
        value: this.usersData.filter(item => this.form.user_ids?.includes(item.usr_id)),
        searchable: true,
        closeOnSelect: false,
        multiple: true,
        label: 'usr_name',
      };
    },
    usersEvents () {
      return {
        select: (evt) => {
          if (!this.form.user_ids) {
            this.$set(this.form, 'user_ids', []);
          }
          this.form.user_ids.push(evt.usr_id);
        },
        remove: (evt) => this.$set(this.form, 'user_ids', this.form.user_ids.filter(id => id !== evt.usr_id)),
      };
    },
  },
  watch: {
    groupId (val) {
      if (val) {
        this.$nextTick(() => {
          this.formManageInputPlaceholder(false);
        });
      }
    },
  },
  mounted () {
    this.checkGetGroupId();
    this.fetchLocations();
    this.fetchUsersList();
  },
  methods: {
    ...mapActions({
      getLocations: 'getLocations',
      getOneGroup: 'getOneGroup',
      postGroups: 'postGroups',
      patchGroup: 'patchGroup',
      getUsers: 'getUsers',
    }),
    async fetchLocations () {
      const params = {
        lct_active: 1,
      };
      try {
        const res = await this.getLocations(params);
        this.locations = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    checkGetGroupId () {
      this.groupId = parseInt(this.$route.params.group_id, 10) || null;
      if (this.groupId) {
        this.fetchGroup();
      }
    },
    async fetchUsersList () {
      const params = {
        // grp_id: this.$route.params.group_id
        usr_active: 1,
      };
      try {
        const res = await this.getUsers(params);
        const tempData = res.data.data.map(responseObj => this.transformApiDataObject(responseObj, {}, { isKeepClone: false }));
        this.usersData = this.addAdditionalData(tempData);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    addAdditionalData (tempData) {
      function fullUserName (data) {
        const userId = data.usr_id || '';
        const firstName = data.usr_first_name || '';
        const lastName = data.usr_last_name || '';
        return `${userId} - ${firstName} ${lastName}`;
      }

      for (const data of tempData) {
        data.usr_name = fullUserName(data);
      }

      return tempData;
    },
    async fetchGroup () {
      try {
        const res = await this.getOneGroup({ id: this.groupId });
        this.form = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        this.formBaseData = JSON.parse(JSON.stringify(this.form));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('group params:', this.form);
    },
    async manageGroup () {
      this.isApiLoading = true;
      if (this.groupId) {
        await this.editGroup();
      } else {
        await this.saveGroup();
      }
      this.isApiLoading = false;
    },
    async editGroup () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.patchGroup(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('group params:', this.group);
    },
    async saveGroup () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.postGroups(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('group params:', this.group);
    },
    handleSuccessRes (response) {
      this.showSuccessMsg(response);
      this.routeGoBackCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
#group-add {
  @include sub-page;

  .form {
    @include forms;
  }
}
</style>
