<template>
  <div id="membership-table-list">
    <div class="top-head">
      <div class="left">
        <!-- <router-link :to="getDynamicPreviousRoute" class="btn" v-if="canGoBack">
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t('app.back') }}</span>
          </span>
        </router-link> -->
        <span>{{ $tc('app.membership.title', 2) }}</span>
        <span
          :title="$i18n.tc('app.client.title', 1)"
          v-if="isMultipleClients && clientData.name"
          >({{ clientData.name }})</span
        >
      </div>
      <div class="center">
        <table-filters
          :values="routeQuery"
          :display="tableFilterDisplay"
          @search-input="routeQuery.search = $event.target.value"
          @active-status-select="activeStatusSelected"
        />
      </div>
      <div class="right">
        <router-link
          :to="{
            name: 'MembershipAdd',
            params: { user_id: $route.params.user_id },
          }"
          v-if="checkHasPermission('create-memberships')"
        >
          <button class="acc">
            <span>
              <i class="fa fa-plus"></i>
              <span>{{ $t('app.membership.addNew') }}</span>
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <transition name="slide-left">
      <div class="table-wrap" v-if="tableShowFunc">
        <table-wrapper
          class="table"
          :rows="membershipsData"
          :columns="columnsToShow"
          :options="tableOptions"
          @row-select="rowSelect"
        ></table-wrapper>
        <pagination-wrap
          :route-query="routeQuery"
          :pagination="pagination"
        ></pagination-wrap>
      </div>
    </transition>
  </div>
</template>

<script>
import TableFilters from '@/components/Shared/table-filters/TableFilters.vue';
import TableWrapper from '@/components/Shared/table-wrap/TableWrapper.vue';
import { mapActions } from 'vuex';
export default {
  name: 'MembershipTableList',
  components: {
    TableFilters,
    TableWrapper,
  },
  data () {
    return {
      membershipsData: [],
      tableShowFunc: false,
      activeStatusSelection: true,
      pagination: {
        totalPages: 0,
      },
      routeQuery: {
        page: 1,
        search: '',
      },
      keysTransform: ['page'],
      transformMap: {
        mmb_id: 'id',
        mmb_name: 'name',
        mmb_description: 'description',
        mmb_phone: 'phone',
        mmb_amount: 'amount',
        usr_id_member: 'usr_id',
        mmb_active: '_isActiveToggle',
      },
    };
  },
  computed: {
    canGoBack () {
      // const routes = ['MembershipTableListAll', 'MembershipTableUser']
      const routes = ['UserTableListAll', 'UserTableListGroups'];
      return routes.includes(this.previousRouteData?.name);
      // return !routes.includes(this.$route.name)
    },
    columnsToShow () {
      return [
        {
          name: 'id',
          hidden: true,
        },
        {
          name: 'fullUserName',
          label: this.$i18n.t('auth.name'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'description',
          label: this.$i18n.t('app.description'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'mmt_name',
          label: this.$i18n.t('app.type'),
          style: {
            width: '250px',
          },
        },
        {
          name: 'mmb_date_to',
          type: 'date',
          format: '',
          label: this.$i18n.t('app.expireDate'),
          style: {
            width: '150px',
          },
        },
        // {
        //   name: 'mmb_payment_date',
        //   label: this.$i18n.t('app.paymentDate'),
        //   style: {
        //     width: '150px'
        //   }
        // },
        {
          name: 'amount',
          label: this.$i18n.t('app.amount'),
        }
        // {
        //   name: 'activate',
        //   label: this.$i18n.t('selection.activeStatus'),
        //   permissions: 'activate-deactivate-memberships',
        //   isMobileHeader: true,
        //   slots: {
        //     activate: true
        //   }
        // }
      ];
    },
    tableOptions () {
      return {
        rowClasses: {
          selectable: this.checkHasPermission('edit-memberships'),
        },
      };
    },
    tableFilterDisplay () {
      return {
        roles: false,
        activeStatus: true,
        // activeStatus: this.checkHasPermission('activate-deactivate-memberships')
      };
    },
  },
  watch: {
    $route (newValue, oldValue) {
      this.routeQuery = { ...this.queryStringToInt(this.$route.query, this.keysTransform) };
      this.fetchMembershipsList();
    },
  },
  mounted () {
    this.routeQuery = { ...this.routeQuery, ...this.queryStringToInt(this.$route.query, this.keysTransform) };
    this.$nextTick(() => (this.tableShowFunc = true));
    this.fetchMembershipsList();
  },
  beforeDestroy () {
    this.$nextTick(() => (this.tableShowFunc = false));
  },
  methods: {
    ...mapActions({
      getMemberships: 'getMemberships',
    }),
    async fetchMembershipsList () {
      const params = {
        search: this.$route.query.search || null,
        page: this.$route.query.page || null,
        usr_id: this.$route.params.user_id || null,
        perPage: this.$route.query.perPage || 5,
        usr_active: this.activeStatusSelection, // Used for user active
      };
      try {
        const res = await this.getMemberships(params);
        let tempData = res.data.data || [];
        tempData = tempData.map(responseObj => this.transformApiDataObject(responseObj, this.transformMap, { isKeepClone: false }));
        this.membershipsData = this.addAdditionalData(tempData);
        this.pagination.totalPages = res.data.meta?.pageCount || 0;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    activeStatusSelected (evt) {
      this.activeStatusSelection = evt;
      this.fetchMembershipsList();
    },
    async rowSelect (rowEvt) {
      this.$router.push({
        name: 'MembershipEdit',
        params: {
          membership_id: rowEvt.id,
        },
      });
    },
    addAdditionalData (tempData) {
      function fullUserName (data) {
        const firstName = data.usr_first_name_member || '';
        const lastName = data.usr_last_name_member || '';
        return `${firstName} ${lastName}`;
      }

      for (const data of tempData) {
        data.fullUserName = fullUserName(data);
      }

      return tempData;
    },
  },
};
</script>

<style lang="scss" scoped>
#membership-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;
}
</style>
